import $ from "jquery";
import _ from "lodash";

window.mkjobs_search_mixin = function() {
  return {
    data: {
      // elid: "",
      url: "/local/job/ajax.php",
      action: "",
      sesskey: "",
      searchtext: "",
      fields: "*",
      filters: {},
      page: 2,
      perpage: 10,
      items: [],
      onfetch: false,
      loadmore: true
    },
    methods: {
      fetch() {
        if (!this.onfetch) {
          this.onfetch = true;
          var params = {
            action: this.action,
            sesskey: this.sesskey,
            searchtext: this.searchtext,
            fields: this.fields,
            page: this.page,
            perpage: this.perpage
          };
          for (var key in this.filters) {
            params[key] = this.filters[key];
          }
          $.post(
            this.url,
            params,
            function(data) {
              this.onfetch = false;
              if (data.data) {
                _.forEach(data.data, item => {
                  this.items.push(item);
                });
                this.loadmore = data.data.length > 0;
                this.page++;
              }
            }.bind(this)
          );
        }
      }
    }
  };
};

window.mkjobs_maplocation_mixin = function() {
  return {
    data: {
      id: "",
      locations: [],
      map_editor: null,
      markers: []
    },
    methods: {
      view(mar) {
        this.map_editor.map.setCenter(mar.getPosition());
        if (this.map_editor.infowindow) this.map_editor.infowindow.close();
        this.map_editor.map.panTo(mar.getPosition());
        this.map_editor.infowindow = new window.google.maps.InfoWindow({
          content: this.map_editor.getInfoWindowHTML(mar.customInfo.title)
        });
        this.map_editor.infowindow.open(this.map_editor.map, mar);
      }
    },
    mounted() {
      this.map_editor = new window.MapEditor(this.id);
      this.map_editor.initialize();

      for (let i = 0; i < this.locations.length; i++) {
        this.markers.push(
          this.map_editor.addMarker(
            {
              lat: this.locations[i].lat,
              lng: this.locations[i].lng
            },
            this.locations[i].info
          )
        );
      }

      if (this.markers.length) {
        this.view(this.markers[0]);
      }
    }
  };
};
