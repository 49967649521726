import $ from "jquery";
import Vue from "vue";
import "bootstrap";
import "./mixins";

function MapEditor(elementID, type, saveCallback) {
  this.element = window.document.getElementById(elementID);
  this.map = null;
  this.type = type || "marker";
  this.infowindow = null;
  this.cfg = {
    defaultZoom: 17,
    defaultCenter: new window.google.maps.LatLng(10.0298914, 105.7710552),
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    polyOptions: {
      strokeWeight: 2,
      strokeColor: "#FF0000",
      fillColor: "#FF0000",
      fillOpacity: 0.2,
      editable: false
    },
    markerOptions: {
      draggable: false
    }
  };
}

MapEditor.prototype.initialize = function() {
  this.map = new window.google.maps.Map(this.element, {
    zoom: this.cfg.defaultZoom,
    center: this.cfg.defaultCenter,
    mapTypeId: this.cfg.mapTypeId,
    zoomControl: true
  });

  this.registerListeners();
};

MapEditor.prototype.registerListeners = function() {
  //
};

MapEditor.prototype.getInfoWindowHTML = function(title) {
  var html = '<div class="vimap-infowindow">' + title + "</div>";
  return html;
};

MapEditor.prototype.addMarker = function(data, title) {
  var mar = new window.google.maps.Marker({
    map: this.map,
    draggable: false,
    animation: window.google.maps.Animation.DROP,
    position: data,
    customInfo: {
      title: title
    }
  });

  mar.addListener(
    "click",
    function(e) {
      if (this.infowindow) {
        this.infowindow.close();
      }
      this.infowindow = new window.google.maps.InfoWindow({
        content: this.getInfoWindowHTML(mar.customInfo.title)
      });
      window.google.maps.event.addListener(
        this.infowindow,
        "closeclick",
        function() {
          this.infowindow = null;
        }.bind(this)
      );
      this.infowindow.open(this.map, mar);
    }.bind(this)
  );

  return mar;
};

window.MapEditor = MapEditor;

function open_popup(url) {
  var w = 880;
  var h = 570;
  var l = Math.floor((screen.width - w) / 2);
  var t = Math.floor((screen.height - h) / 2);
  var win = window.open(
    url,
    "",
    "scrollbars=1,width=" + w + ",height=" + h + ",top=" + t + ",left=" + l
  );
}
window.open_popup = open_popup;

window.$ = window.jQuery = $;
window.Vue = Vue;

$(function() {
  // $(".hash-area li").on("click", function() {
  //   $($(this).data("target")).val($(this).data("title"));
  // });
});
